



































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Bacteria from '@/modules/shared/entities/Bacteria'

import EcBrIcon from '@/modules/shared/components/icons/EcBrIcon.vue'
import KpBrIcon from '@/modules/shared/components/icons/KpBrIcon.vue'
import SeBrIcon from '@/modules/shared/components/icons/SeBrIcon.vue'
import PaBrIcon from '@/modules/shared/components/icons/PaBrIcon.vue'
import COVIDIcon from '@/modules/shared/components/icons/COVIDIcon.vue'
import SaBrIcon from '@/modules/shared/components/icons/SaBrIcon.vue'
import EfBrIcon from '@/modules/shared/components/icons/EfBrIcon.vue'

const SharedModule = namespace('shared')

@Component({
  components: {
    EcBrIcon,
    KpBrIcon,
    SeBrIcon,
    PaBrIcon,
    COVIDIcon,
    SaBrIcon,
    EfBrIcon,
  },
})
export default class BacteriaMenu extends Vue {
  @SharedModule.State
  private readonly bacteria!: Bacteria[]

  private get currentBacteria() {
    return this.$route.params.name
  }

  private set currentBacteria(name: string) {
    this.$router.replace({ params: { name } })
  }

  private get menuProps() {
    return {
      nudgeTop: -10,
      offsetY: true,
      transition: 'slide-y-transition',
    }
  }
}
