<template>
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0058 14.0427C16.9678 14.9384 16.5667 15.7323 15.9593 16.2903C15.3519 16.8483 14.527 17.1809 13.6312 17.143C10.3506 17.0041 7.4392 15.5511 5.38252 13.3127C3.32599 11.0741 2.12437 8.05026 2.26329 4.76967C2.30123 3.87389 2.70238 3.08007 3.30977 2.52203C3.91716 1.964 4.74206 1.63139 5.63784 1.66932C7.42925 1.74519 8.81405 3.25245 8.73818 5.04387C8.67512 6.53304 9.22235 7.90757 10.152 8.93075C11.0929 9.94357 12.4163 10.605 13.9054 10.6681C14.8012 10.706 15.595 11.1072 16.1531 11.7146C16.7111 12.322 17.0437 13.1469 17.0058 14.0427Z" fill="#E63950"/>
    <path d="M16.1549 11.7147C16.7129 12.3221 17.0455 13.147 17.0076 14.0427C16.9697 14.9385 16.5685 15.7323 15.9611 16.2904C15.3537 16.8484 14.5288 17.181 13.6331 17.1431C10.3525 17.0042 7.44103 15.5512 5.38435 13.3128L10.1538 8.93083C11.0948 9.94365 12.4181 10.6051 13.9073 10.6682C14.803 10.7061 15.5969 11.1073 16.1549 11.7147Z" fill="#AE2538"/>
    <path d="M6.1301 13.959L6.84366 14.7543L5.39703 16.1082L4.68347 15.3129L6.1301 13.959Z" fill="#FE909E"/>
    <path d="M9.19105 18.463L8.22719 18.0211L9.13412 16.0339L10.098 16.4757L9.19105 18.463Z" fill="#FE909E"/>
    <path d="M13.1055 17.1204L14.1669 17.1563L14.0807 18.7848L13.0192 18.7488L13.1055 17.1204Z" fill="#FE909E"/>
    <path d="M16.8755 15.3365L18.3268 16.9541L17.5315 17.6983L16.0802 16.0809L16.8755 15.3365Z" fill="#FE909E"/>
    <path d="M17.0352 13.5032L18.6416 13.5575L18.5835 14.6509L16.9771 14.5966L17.0352 13.5032Z" fill="#FE909E"/>
    <path d="M17.9449 10.3742L18.4894 11.3012L16.6422 12.4535L16.0977 11.5265L17.9449 10.3742Z" fill="#FE909E"/>
    <path d="M0.597656 4.15869L2.21638 4.22724L2.17068 5.30639L0.551957 5.23784L0.597656 4.15869Z" fill="#FE909E"/>
    <path d="M0.738235 9.00143L0.440117 7.96375L2.49637 7.37346L2.79448 8.41129L0.738235 9.00143Z" fill="#FE909E"/>
    <path d="M3.54462 10.7198L4.10519 11.6431L2.22375 12.7854L1.66319 11.862L3.54462 10.7198Z" fill="#FE909E"/>
    <path d="M2.84698 0.0877308L4.3331 1.70514L3.5378 2.43583L2.05167 0.818412L2.84698 0.0877308Z" fill="#FE909E"/>
    <path d="M8.48752 0.408989L9.36006 1.0456L8.08339 2.79546L7.21085 2.15884L8.48752 0.408989Z" fill="#FE909E"/>
    <path d="M9.01672 6.17436L8.79968 5.11675L10.9547 4.6738L11.1717 5.73142L9.01672 6.17436Z" fill="#FE909E"/>
    <path d="M11.3902 9.9923L10.5544 9.30868L11.9851 7.56028L12.8208 8.24375L11.3902 9.9923Z" fill="#FE909E"/>
    <path d="M15.0625 10.8856L14.0134 10.629L14.5516 8.42726L15.6007 8.68396L15.0625 10.8856Z" fill="#FE909E"/>
    <path d="M5.4328 6.52548C4.54025 6.48768 3.84483 5.73075 3.88262 4.83821C3.92042 3.94566 4.67735 3.25023 5.56989 3.28803C6.46244 3.32583 7.15787 4.08276 7.12007 4.9753C7.08227 5.86785 6.32534 6.56327 5.4328 6.52548Z" fill="#EC5569"/>
    <path d="M13.7023 15.5247C12.8098 15.4869 12.1144 14.73 12.1522 13.8375C12.19 12.9449 12.9469 12.2495 13.8394 12.2873C14.732 12.3251 15.4274 13.082 15.3896 13.9746C15.3518 14.8671 14.5949 15.5625 13.7023 15.5247Z" fill="#EC5569"/>
    <path d="M5.39776 7.38168L6.12844 8.17698L5.33314 8.90766L4.60245 8.11236L5.39776 7.38168Z" fill="#EC5569"/>
    <path d="M8.1543 11.5059L7.35563 12.2397L6.62185 11.441L7.42052 10.7073L8.1543 11.5059Z" fill="#EC5569"/>
    <path d="M10.5657 13.0052L11.2964 13.8005L10.5011 14.5312L9.77042 13.7359L10.5657 13.0052Z" fill="#EC5569"/>
    <path d="M10.1165 10.8241L10.8472 11.6194L10.0519 12.35L9.3212 11.5547L10.1165 10.8241Z" fill="#EC5569"/>
    <path d="M7.53448 8.01205L8.26516 8.80735L7.46985 9.53803L6.73917 8.74273L7.53448 8.01205Z" fill="#EC5569"/>
    <path d="M13.9294 10.1287C12.5913 10.072 11.4054 9.48127 10.5581 8.55885C9.71058 7.63655 9.22219 6.405 9.27886 5.06689C9.36705 2.98419 7.74448 1.21812 5.66178 1.12992C3.57908 1.04172 1.81302 2.66429 1.72482 4.74699C1.57995 8.16781 2.84087 11.324 4.99007 13.6744C7.1504 16.0146 10.1887 17.5379 13.6095 17.6827C15.6922 17.7709 17.4583 16.1484 17.5464 14.0657C17.6346 11.983 16.0121 10.2169 13.9294 10.1287ZM13.6552 16.6036C10.5257 16.4711 7.75215 15.078 5.78874 12.9406C3.82504 10.8036 2.67144 7.92222 2.80397 4.79269C2.86703 3.30352 4.12692 2.146 5.61609 2.20907C7.10525 2.27213 8.26277 3.53202 8.19971 5.02119C8.13024 6.66155 8.73677 8.16823 9.7594 9.29262C10.7933 10.4066 12.2433 11.1384 13.8837 11.2078C15.3728 11.2709 16.5304 12.5308 16.4673 14.02C16.4042 15.5091 15.1443 16.6667 13.6552 16.6036Z" fill="#E63950"/>
    <path d="M17.547 14.0656C17.4588 16.1483 15.6927 17.7708 13.61 17.6826C10.1892 17.5378 7.15092 16.0145 4.99059 13.6743L5.78926 12.9405C7.75266 15.0779 10.5262 16.4709 13.6557 16.6035C15.1449 16.6665 16.4048 15.509 16.4678 14.0199C16.5309 12.5307 15.3734 11.2708 13.8842 11.2077C12.2438 11.1383 10.7938 10.4065 9.75991 9.29251L10.5586 8.55873C11.4059 9.48116 12.5918 10.0719 13.9299 10.1286C16.0126 10.2168 17.6352 11.9829 17.547 14.0656Z" fill="#E63950"/>
    <path d="M6.99294 11.8461L7.79161 11.1123L8.15332 11.506L7.35465 12.2398L6.99294 11.8461Z" fill="#EC5569"/>
    <path d="M16.9425 7.6811L16.4344 7.11277L17.577 6.08064L18.0851 6.64897L16.9425 7.6811Z" fill="#EC5569"/>
    <path d="M20.0291 3.39595L19.2919 3.20159L19.6768 1.71075L20.414 1.90511L20.0291 3.39595Z" fill="#EC5569"/>
    <path d="M21.6189 6.47263L21.7074 5.7154L23.2377 5.88645L23.1491 6.64368L21.6189 6.47263Z" fill="#EC5569"/>
    <path d="M19.9693 8.31815L20.6642 8.00459L21.3046 9.40485L20.6097 9.71841L19.9693 8.31815Z" fill="#EC5569"/>
    <path d="M13.3794 5.46892L13.2177 4.72388L14.7208 4.3897L14.8824 5.13475L13.3794 5.46892Z" fill="#EC5569"/>
    <path d="M16.4854 2.63433L15.7956 2.95896L15.1329 1.56913L15.8227 1.2445L16.4854 2.63433Z" fill="#EC5569"/>
    <path d="M21.624 6.4933C21.7905 7.37429 21.2138 8.21966 20.3329 8.38614C19.4519 8.55262 18.6065 7.97597 18.44 7.09498C18.3558 6.6493 18.1012 6.29063 17.7605 6.05818C17.4197 5.82572 16.9929 5.7196 16.5472 5.80382C15.6662 5.97029 14.8208 5.39364 14.6544 4.51265C14.4879 3.63166 15.0645 2.7863 15.9455 2.61982C17.2615 2.37113 18.5612 2.67515 19.5852 3.38307C20.618 4.07817 21.3753 5.17728 21.624 6.4933Z" fill="#E63950"/>
    <path d="M21.6227 6.49342C21.7892 7.37441 21.2125 8.21978 20.3315 8.38625C19.4506 8.55273 18.6052 7.97608 18.4387 7.09509C18.3545 6.64941 18.0999 6.29075 17.7592 6.05829L19.5839 3.38318C20.6167 4.07828 21.374 5.17739 21.6227 6.49342Z" fill="#AE2538"/>
    <path d="M19.9059 2.93721C18.7576 2.15403 17.308 1.81236 15.8434 2.08912C14.6759 2.30973 13.9012 3.44543 14.1218 4.6129C14.3424 5.78036 15.4781 6.55506 16.6456 6.33445C16.9429 6.27828 17.2196 6.34692 17.4573 6.49981C17.6864 6.66534 17.8513 6.89797 17.9075 7.19522C18.1281 8.36269 19.2638 9.13738 20.4313 8.91677C21.5987 8.69616 22.3734 7.56046 22.1528 6.39299C21.8761 4.92841 21.033 3.72456 19.9059 2.93721ZM20.2307 7.85544C19.647 7.96575 19.0791 7.5784 18.9688 6.99466C18.8585 6.41093 18.5248 5.92437 18.0599 5.61648C17.6035 5.29594 17.0288 5.16281 16.445 5.27312C15.8613 5.38342 15.2935 4.99607 15.1832 4.41234C15.0728 3.82861 15.4602 3.26076 16.0439 3.15045C17.2114 2.92984 18.363 3.20688 19.2822 3.82453C20.1927 4.455 20.8709 5.42608 21.0915 6.59355C21.2018 7.17728 20.8144 7.74513 20.2307 7.85544Z" fill="#E63950"/>
    <path d="M22.1537 6.39279C22.3743 7.56026 21.5996 8.69596 20.4321 8.91657C19.2646 9.13718 18.1289 8.36249 17.9083 7.19502C17.8521 6.89777 17.6872 6.66514 17.4582 6.4996L18.0607 5.61628C18.5257 5.92417 18.8593 6.41073 18.9697 6.99446C19.08 7.5782 19.6478 7.96555 20.2315 7.85524C20.8153 7.74493 21.2026 7.17708 21.0923 6.59335C20.8717 5.42588 20.1935 4.4548 19.283 3.82433L19.8856 2.94101L19.9067 2.93701C21.0338 3.72436 21.8769 4.92821 22.1537 6.39279Z" fill="#E63950"/>
  </svg>
</template>

<script>
export default {
  name: 'EcBrIcon',
}
</script>
