<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.19844 20.3523H5.65344C5.37782 20.3507 5.11407 20.2399 4.91988 20.0443C4.72568 19.8487 4.61685 19.5842 4.61719 19.3086V11.0823C4.61685 10.8067 4.72568 10.5422 4.91988 10.3466C5.11407 10.151 5.37782 10.0402 5.65344 10.0386H24.1197C24.3951 10.0406 24.6585 10.1514 24.8524 10.347C25.0464 10.5426 25.155 10.8069 25.1547 11.0823V19.3086C25.155 19.584 25.0464 19.8483 24.8524 20.0439C24.6585 20.2395 24.3951 20.3503 24.1197 20.3523H20.5734" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.5027 15.705H10.2752C9.70362 15.705 9.24023 16.1684 9.24023 16.74V23.7963C9.24023 24.3679 9.70362 24.8313 10.2752 24.8313H19.5027C20.0743 24.8313 20.5377 24.3679 20.5377 23.7963V16.74C20.5377 16.1684 20.0743 15.705 19.5027 15.705Z" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.23829 9.75261V5.81511C9.23728 5.54058 9.34487 5.27678 9.53758 5.08125C9.7303 4.88572 9.99251 4.77433 10.267 4.77136H19.5095C19.7839 4.77433 20.046 4.88577 20.2385 5.08133C20.4311 5.27689 20.5384 5.54069 20.537 5.81511V9.75261" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.8457 13.2863H22.1607" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5098 13.2863H20.2385" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Printer',
}
</script>
