var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"experiment-table":""}},[_c('experiment-filter',{attrs:{"isCovid":_vm.isCovid}}),_c('v-data-table',{staticClass:"--striped --clickable",attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0","options":_vm.options,"server-items-length":_vm.experiments.length,"headers":_vm.headers,"items":_vm.experiments},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.identification",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showExperimentDetails(item.id)}}},[_vm._v(" "+_vm._s(item.identification)+" ")])]}},{key:"item.subSpecieName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.subSpecieName)+" ")])]}},{key:"item.host",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'font-italic': item.host !== 'NA' }},[_vm._v(" "+_vm._s(item.host)+" ")])]}},{key:"item.resistome",fn:function(ref){
var item = ref.item;
return [(item.resistome.toString())?_c('a',{staticClass:"d-block text-truncate",staticStyle:{"max-width":"250px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showResistome(item.resistome)}}},[_vm._v(" "+_vm._s(item.resistome)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"body.append",fn:function(){return [_c('p',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersect,
          options: {
            threshold: [0, 0.5, 1.0]
          }
        }),expression:"{\n          handler: onIntersect,\n          options: {\n            threshold: [0, 0.5, 1.0]\n          }\n        }"}]})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('resistome-dialog',{model:{value:(_vm.resistomeDialog),callback:function ($$v) {_vm.resistomeDialog=$$v},expression:"resistomeDialog"}}),_c('experiment-dialog',{model:{value:(_vm.experimentDialog),callback:function ($$v) {_vm.experimentDialog=$$v},expression:"experimentDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }