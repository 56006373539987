<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 12.0046C5.5 12.2807 5.72386 12.5046 6 12.5046C6.27614 12.5046 6.5 12.2807 6.5 12.0046H5.5ZM6 6V5.5C5.72386 5.5 5.5 5.72386 5.5 6H6ZM12.0046 6.5C12.2807 6.5 12.5046 6.27614 12.5046 6C12.5046 5.72386 12.2807 5.5 12.0046 5.5V6.5ZM6.5 12.0046L6.5 6H5.5L5.5 12.0046H6.5ZM6 6.5L12.0046 6.5V5.5L6 5.5V6.5Z" fill="#333333"/>
    <path d="M18.5078 12.006C18.5078 11.7299 18.284 11.506 18.0078 11.506C17.7317 11.506 17.5078 11.7299 17.5078 12.006L18.5078 12.006ZM18.0078 18.0106L18.0078 18.5106C18.284 18.5106 18.5078 18.2868 18.5078 18.0106L18.0078 18.0106ZM12.0032 17.5106C11.7271 17.5106 11.5032 17.7345 11.5032 18.0106C11.5032 18.2868 11.7271 18.5106 12.0032 18.5106L12.0032 17.5106ZM17.5078 12.006L17.5078 18.0106L18.5078 18.0106L18.5078 12.006L17.5078 12.006ZM18.0078 17.5106L12.0032 17.5106L12.0032 18.5106L18.0078 18.5106L18.0078 17.5106Z" fill="#333333"/>
  </svg>
</template>

<script>
export default {
  name: 'FullscreenIcon',
}
</script>
