





















































/* eslint-disable @typescript-eslint/no-explicit-any */
import VMarkerCluster from 'vue2-leaflet-markercluster'
import { Component, Vue } from 'vue-property-decorator'
import { LMap, LTileLayer, LCircleMarker } from 'vue2-leaflet'
import { namespace } from 'vuex-class'
import { latLng } from 'leaflet'

import Coordinate from '@/modules/shared/entities/Coordinate'
import BacteriaFilter from '@/modules/shared/entities/BacteriaFilter'

import ConfirmDialog from '@/modules/shared/components/ConfirmDialog.vue'
import BacteriaMenu from './BacteriaMenu.vue'
import MapControl from './MapControl.vue'
import MapEbursts from './MapEbursts.vue'
import MapMicroreact from './MapMicroreact.vue'

const BacteriaModule = namespace('bacteria')

@Component({
  components: {
    BacteriaMenu,
    ConfirmDialog,
    LCircleMarker,
    LMap,
    LTileLayer,
    MapControl,
    VMarkerCluster,
    MapEbursts,
    MapMicroreact,
  },
})
export default class BacteriaMap extends Vue {
  $refs!: {
    mapContainer: HTMLDivElement;
    map: LMap;
    cluster: any;
  }

  @BacteriaModule.State
  private readonly coordinates!: Coordinate[]

  @BacteriaModule.State
  private readonly mapConfig!: any

  @BacteriaModule.State
  private readonly filter!: BacteriaFilter

  @BacteriaModule.Action
  private readonly fetchExperiments!: (filter: BacteriaFilter) => Promise<void>

  private fullscreen = false

  private confirm = false

  private selectedIds: number[] = []

  private getLatLng(coordinate: Coordinate) {
    return latLng(coordinate.latitude, coordinate.longitude)
  }

  private zoomInOut(amount: number) {
    const map = this.$refs.map.mapObject
    const zoom = map.getZoom()
    map.setZoom(zoom + amount)
  }

  private proceed() {
    const filter = new BacteriaFilter({
      ids: this.selectedIds,
      type: this.filter.type,
    })

    this.fetchExperiments(filter)
    this.closeDialog()
  }

  private closeDialog() {
    this.confirm = false
    this.selectedIds = []
  }

  mounted() {
    this.$nextTick(() => {
      const markers = this.$refs.cluster.mapObject

      markers.on('click', (a: any) => {
        this.selectedIds = [a.layer.options.coordinateId]
        this.confirm = true
      })

      markers.on('clusterclick', (a: any) => {
        try {
          const childMarkers = a.layer.getAllChildMarkers()
          this.selectedIds = childMarkers.map((cM: any) => cM.options.coordinateId)
          this.confirm = true
        } catch (err) {
          console.log(err)
        }
      })

      setTimeout(() => {
        const height = this.$refs.mapContainer.clientHeight
        const map = this.$refs.map.mapObject

        const center = map.getCenter()
        map.setView(center, height < 400 ? 3.0 : 3.5)
      }, 300)
    })

    this.$refs.mapContainer.addEventListener('transitionend', (evt: Event) => {
      if (evt.target === this.$refs.mapContainer) {
        const map = this.$refs.map.mapObject
        const zoom = this.fullscreen ? map.getZoom() + 1 : map.getZoom() - 1

        map.invalidateSize()

        const center = map.getCenter()
        map.setView(center, zoom)
      }
    })
  }
}
