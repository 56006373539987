























import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Resistome from '@/modules/shared/entities/Resistome'

const BacteriaModule = namespace('bacteria')

@Component
export default class ResistomeDialog extends Vue {
  @Prop({ default: false })
  private readonly value!: boolean

  @BacteriaModule.State
  private readonly resistome!: Resistome|null

  @BacteriaModule.Mutation
  private readonly setResistome!: (resistome: Resistome|null) => void

  private get resistomeEntries() {
    return Object.entries(this.resistome ?? {}).filter((e) => e[1] !== '-')
  }

  private close() {
    setTimeout(() => this.setResistome(null), 270)
  }
}
