


































import { Component, Vue } from 'vue-property-decorator'

import Icon from '@/modules/shared/components/Icon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

@Component({
  components: {
    PrimaryButton,
    Icon,
  },
})
export default class Mapmicroreact extends Vue {
  private dialog = false

  private get currentBacteria(): string {
    const { name } = this.$route.params

    return name.toLowerCase()
  }

  private openDialog(event: Event) {
    if (this.$vuetify.breakpoint.smAndDown) {
      event.preventDefault()

      this.dialog = true
    }
  }

  private get microreactImg(): string {
    try {
      // eslint-disable-next-line
      return require(`@/assets/microreact/${this.currentBacteria}.jpeg`)
    } catch (_) {
      return ''
    }
  }
}
