<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5039 3.99461C10.5039 3.71847 10.28 3.49461 10.0039 3.49461C9.72776 3.49461 9.50391 3.71847 9.50391 3.99461L10.5039 3.99461ZM10.0039 9.99921L10.0039 10.4992C10.28 10.4992 10.5039 10.2753 10.5039 9.99921L10.0039 9.99921ZM3.99931 9.49921C3.72317 9.49921 3.49931 9.72306 3.49931 9.99921C3.49931 10.2753 3.72316 10.4992 3.99931 10.4992L3.99931 9.49921ZM9.50391 3.99461L9.50391 9.99921L10.5039 9.99921L10.5039 3.99461L9.50391 3.99461ZM10.0039 9.49921L3.99931 9.49921L3.99931 10.4992L10.0039 10.4992L10.0039 9.49921Z" fill="#333333"/>
    <path d="M13.5039 20.0053C13.5039 20.2815 13.7278 20.5053 14.0039 20.5053C14.28 20.5053 14.5039 20.2815 14.5039 20.0053H13.5039ZM14.0039 14.0007V13.5007C13.7278 13.5007 13.5039 13.7246 13.5039 14.0007H14.0039ZM20.0085 14.5007C20.2846 14.5007 20.5085 14.2769 20.5085 14.0007C20.5085 13.7246 20.2846 13.5007 20.0085 13.5007V14.5007ZM14.5039 20.0053V14.0007H13.5039V20.0053H14.5039ZM14.0039 14.5007H20.0085V13.5007H14.0039V14.5007Z" fill="#333333"/>
  </svg>
</template>

<script>
export default {
  name: 'FullscreenExitIcon',
}
</script>
