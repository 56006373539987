<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12H18" stroke="#333333" stroke-linecap="round"/>
    <path d="M12 6V18" stroke="#333333" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AddIcon',
}
</script>
