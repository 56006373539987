







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { debounce } from '@/modules/shared/utils/functions'

import BacteriaFilter from '@/modules/shared/entities/BacteriaFilter'

import DatePicker from '@/modules/shared/components/DatePicker.vue'
import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import SearchIcon from '@/modules/shared/components/icons/SearchIcon.vue'
import Icon from '@/modules/shared/components/Icon.vue'
import ResistomeAutocomplete from './ResistomeAutocomplete.vue'
import SubSpecieAutocomplete from './SubSpecieAutocomplete.vue'

const BacteriaModule = namespace('bacteria')

@Component({
  components: {
    DatePicker,
    DeleteIcon,
    Icon,
    ResistomeAutocomplete,
    SearchIcon,
    SubSpecieAutocomplete,
  },
})
export default class ExperimentFilter extends Vue {
  @Prop({ default: false })
  private readonly isCovid!: boolean

  @BacteriaModule.State
  private readonly filter!: BacteriaFilter

  @BacteriaModule.Action
  private readonly fetchExperiments!: (filter: BacteriaFilter) => Promise<void>

  private debouncedApplyFilter = debounce(this.applyFilter, 750)

  private applyFilter(name: string, value: string|number|number[]): void {
    const newFilter = this.filter.copyWith({
      [name]: value,
      page: 1,
    })

    this.fetchExperiments(newFilter)
  }

  private clearSearch() {
    const newFilter = BacteriaFilter.empty()

    newFilter.type = this.filter.type

    this.fetchExperiments(newFilter)
  }

  private get startDatePlaceholder() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return this.$t('shared.start') as string
    }

    return this.$t('bacteria.filter.start_date') as string
  }

  private get endDatePlaceholder() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return this.$t('shared.end') as string
    }

    return this.$t('bacteria.filter.end_date') as string
  }
}
