






















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Experiment from '@/modules/shared/entities/Experiment'

import PrinterIcon from '@/modules/shared/components/icons/PrinterIcon.vue'
import DialogSection from '@/modules/shared/components/DialogSection.vue'

const BacteriaModule = namespace('bacteria')

@Component({
  components: {
    DialogSection,
    PrinterIcon,
  },
})
export default class ExperimentDialog extends Vue {
  @Prop({ default: false })
  private readonly value!: boolean

  @BacteriaModule.State
  private readonly experiment!: Experiment

  @BacteriaModule.Mutation
  private readonly setExperiment!: (experiment: Experiment) => void

  private close() {
    setTimeout(() => this.setExperiment(Experiment.empty()), 270)
  }

  private print() {
    window.print()
  }
}
