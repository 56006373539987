






















































import { Component, Prop, Vue } from 'vue-property-decorator'

import AddIcon from '@/modules/shared/components/icons/AddIcon.vue'
import RemoveIcon from '@/modules/shared/components/icons/RemoveIcon.vue'
import FullscreenIcon from '@/modules/shared/components/icons/FullscreenIcon.vue'
import FullscreenExitIcon from '@/modules/shared/components/icons/FullscreenExitIcon.vue'

@Component({
  components: {
    AddIcon,
    FullscreenExitIcon,
    FullscreenIcon,
    RemoveIcon,
  },
})
export default class MapControl extends Vue {
  @Prop({ required: true })
  private readonly fullscreen!: boolean

  private get isDesktop(): boolean {
    return this.$vuetify.breakpoint.mdAndUp
  }

  private get buttonSize(): number {
    return this.isDesktop ? 40 : 30
  }

  private get iconSize(): number {
    return this.buttonSize * 0.6
  }
}
