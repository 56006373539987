<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.773 18.1824C13.6902 17.8866 13.8547 17.5931 14.1469 17.5154L15.1903 17.2378C16.0563 17.0074 16.9535 17.5292 17.1988 18.406C17.2816 18.7018 17.586 18.9083 17.8782 18.8306C18.2851 18.7223 18.6946 18.7836 19.0369 18.965C19.4401 19.1642 19.7566 19.5339 19.8867 19.9987L20.1823 21.0551C20.2651 21.3509 20.1006 21.6443 19.8084 21.722C19.5162 21.7998 19.2216 21.6285 19.1389 21.3327L18.8433 20.2763C18.7605 19.9806 18.4659 19.8092 18.1738 19.8869C17.3077 20.1173 16.4007 19.5604 16.1554 18.6836C16.0726 18.3878 15.7781 18.2164 15.4859 18.2942L14.4425 18.5718C14.1503 18.6495 13.8557 18.4782 13.773 18.1824Z" fill="#BD4859"/>
    <path d="M25.494 23.7047C25.5805 23.9994 25.4198 24.295 25.1287 24.3766L24.089 24.6679C23.2261 24.9097 22.3224 24.3996 22.0659 23.5261C21.9794 23.2314 21.6724 23.0289 21.3812 23.1104C20.9758 23.224 20.5655 23.1681 20.221 22.9912C19.8153 22.7973 19.4941 22.4318 19.3581 21.9686L19.0491 20.9161C18.9625 20.6214 19.1232 20.3258 19.4144 20.2442C19.7055 20.1627 20.0022 20.3301 20.0887 20.6248L20.3978 21.6773C20.4843 21.972 20.781 22.1395 21.0722 22.0579C21.9351 21.8162 22.849 22.3612 23.1056 23.2348C23.1921 23.5295 23.4888 23.697 23.7799 23.6154L24.8196 23.3241C25.1107 23.2426 25.4074 23.41 25.494 23.7047Z" fill="#BD4859"/>
    <path d="M16.8425 17.5524C16.7162 18.2142 16.3207 18.7502 15.7918 19.0823C15.2628 19.4145 14.5905 19.5488 13.9037 19.3975C11.3885 18.8434 9.30043 17.3615 7.95677 15.4121C6.61324 13.4627 6.0142 11.0458 6.47694 8.62197C6.60329 7.96012 6.99874 7.42409 7.5277 7.09198C8.05666 6.75986 8.72894 6.62549 9.41573 6.77679C10.7892 7.07936 11.6939 8.39199 11.4412 9.71558C11.2312 10.8159 11.5042 11.9146 12.1104 12.8042C12.7263 13.6877 13.6753 14.362 14.817 14.6136C15.5038 14.7649 16.0726 15.1726 16.4372 15.7016C16.8017 16.2305 16.9689 16.8905 16.8425 17.5524Z" fill="#E63950"/>
    <path d="M8.68264 11.0439L9.16 11.7365L8.46739 12.1714L7.99003 11.4788L8.68264 11.0439Z" fill="#CC2E43"/>
    <path d="M9.66311 9.54342L10.1405 10.236L9.44786 10.6709L8.9705 9.97829L9.66311 9.54342Z" fill="#CC2E43"/>
    <path d="M8.61233 8.02828L9.08969 8.72089L8.39708 9.15576L7.91972 8.46315L8.61233 8.02828Z" fill="#CC2E43"/>
    <path d="M10.2895 14.509L9.59396 14.9457L9.11458 14.2502L9.81012 13.8135L10.2895 14.509Z" fill="#CC2E43"/>
    <path d="M11.8793 16.8477L11.1838 17.2844L10.7044 16.5888L11.4 16.1521L11.8793 16.8477Z" fill="#CC2E43"/>
    <path d="M10.2256 11.7837L10.703 12.4763L10.0104 12.9111L9.533 12.2185L10.2256 11.7837Z" fill="#CC2E43"/>
    <path d="M11.8174 14.122L12.2948 14.8146L11.6022 15.2495L11.1248 14.5569L11.8174 14.122Z" fill="#CC2E43"/>
    <path d="M13.3057 16.2441L13.783 16.9367L13.0904 17.3716L12.6131 16.679L13.3057 16.2441Z" fill="#CC2E43"/>
    <path d="M15.2178 16.4565L15.6952 17.1491L15.0026 17.584L14.5252 16.8914L15.2178 16.4565Z" fill="#CC2E43"/>
    <path d="M14.8928 14.2152C13.8669 13.9892 13.0163 13.3864 12.4627 12.5831C11.909 11.7799 11.6659 10.7957 11.8546 9.80701C12.1484 8.26821 11.0883 6.73019 9.49153 6.37842C7.89473 6.02665 6.35671 6.99233 6.06294 8.53113C5.58042 11.0586 6.21085 13.5821 7.61373 15.6276C9.02631 17.6671 11.2046 19.2187 13.8273 19.7965C15.4241 20.1483 16.9621 19.1826 17.2559 17.6438C17.5497 16.105 16.4896 14.567 14.8928 14.2152ZM13.9795 18.9992C11.5801 18.4706 9.59195 17.0522 8.30927 15.1909C7.02634 13.3298 6.44889 11.0256 6.89032 8.7134C7.10037 7.61312 8.19757 6.92423 9.33931 7.17575C10.4811 7.42727 11.2373 8.52447 11.0272 9.62474C10.7958 10.8367 11.1003 12.042 11.7672 13.0198C12.4439 13.9914 13.4829 14.7355 14.7406 15.0125C15.8823 15.264 16.6386 16.3612 16.4285 17.4615C16.2185 18.5618 15.1213 19.2507 13.9795 18.9992Z" fill="#FE909E"/>
    <path d="M17.164 17.66C16.8715 19.1898 15.3403 20.1498 13.7505 19.8001C11.1393 19.2257 8.97065 17.6832 7.56429 15.6557L8.25677 15.2216C9.5338 17.072 11.5132 18.482 13.902 19.0074C15.0388 19.2575 16.1311 18.5726 16.3403 17.4788C16.5494 16.385 15.7965 15.2943 14.6598 15.0443C13.4077 14.7688 12.3732 14.0291 11.6995 13.0632L12.3919 12.6291C12.9431 13.4277 13.7899 14.0269 14.8113 14.2516C16.4011 14.6013 17.4565 16.1303 17.164 17.66Z" fill="#FE909E"/>
    <path d="M21.0204 11.4011C21.0603 11.6134 20.9268 11.8088 20.7168 11.8452L19.967 11.9752C19.3446 12.0831 18.7467 11.6621 18.6284 11.0327C18.5885 10.8203 18.3874 10.6568 18.1774 10.6932C17.885 10.7439 17.6012 10.6757 17.372 10.5275C17.1011 10.363 16.9016 10.0844 16.8389 9.75074L16.6963 8.99243C16.6564 8.78008 16.7899 8.58469 16.9999 8.54829C17.2099 8.5119 17.4062 8.65011 17.4461 8.86246L17.5887 9.62077C17.6286 9.83307 17.8249 9.97133 18.0349 9.93493C18.6572 9.82705 19.2599 10.2733 19.3782 10.9027C19.4181 11.115 19.6144 11.2533 19.8244 11.2169L20.5742 11.0869C20.7842 11.0505 20.9805 11.1887 21.0204 11.4011Z" fill="#BD4859"/>
    <path d="M13.136 6.80616C13.0934 6.59438 13.2245 6.39719 13.4339 6.35803L14.182 6.2182C14.8029 6.10214 15.406 6.51529 15.5324 7.14309C15.575 7.35492 15.7781 7.51576 15.9876 7.4766C16.2794 7.42207 16.564 7.48662 16.795 7.63178C17.068 7.79269 17.271 8.06864 17.338 8.4015L17.4903 9.15792C17.5329 9.36974 17.4019 9.56689 17.1924 9.60605C16.9829 9.6452 16.7849 9.50957 16.7422 9.29775L16.59 8.54133C16.5474 8.32955 16.3493 8.19387 16.1399 8.23303C15.519 8.34909 14.9107 7.91078 14.7844 7.28292C14.7417 7.07115 14.5437 6.93547 14.3342 6.97463L13.5862 7.11446C13.3767 7.15361 13.1787 7.01798 13.136 6.80616Z" fill="#BD4859"/>
    <path d="M18.8114 11.6617C18.9409 11.2067 19.252 10.8564 19.6446 10.657C20.0372 10.4577 20.5185 10.4055 20.9926 10.554C22.7288 11.0979 24.1076 12.2641 24.9338 13.7109C25.76 15.1578 26.0336 16.8853 25.5596 18.5516C25.4302 19.0066 25.119 19.3569 24.7265 19.5563C24.3339 19.7556 23.8525 19.8078 23.3784 19.6593C22.4304 19.3623 21.874 18.3881 22.1329 17.4781C22.3481 16.7217 22.2232 15.9364 21.8511 15.2765C21.4717 14.6204 20.8451 14.0898 20.057 13.8429C19.5829 13.6943 19.2076 13.3738 18.9834 12.9812C18.7592 12.5886 18.682 12.1167 18.8114 11.6617Z" fill="#E63950"/>
    <path d="M24.147 16.7226L23.8534 16.2086L24.3675 15.9475L24.661 16.4616L24.147 16.7226Z" fill="#CC2E43"/>
    <path d="M23.3657 17.7148L23.0722 17.2008L23.5862 16.9397L23.8798 17.4538L23.3657 17.7148Z" fill="#CC2E43"/>
    <path d="M24.0103 18.8423L23.7167 18.3282L24.2308 18.0671L24.5243 18.5812L24.0103 18.8423Z" fill="#CC2E43"/>
    <path d="M23.2333 14.1941L23.7495 13.932L24.0443 14.4482L23.5281 14.7104L23.2333 14.1941Z" fill="#CC2E43"/>
    <path d="M22.2626 12.4554L22.7788 12.1932L23.0736 12.7094L22.5574 12.9716L22.2626 12.4554Z" fill="#CC2E43"/>
    <path d="M23.1118 16.1086L22.8183 15.5946L23.3323 15.3335L23.6259 15.8476L23.1118 16.1086Z" fill="#CC2E43"/>
    <path d="M22.1392 14.3698L21.8456 13.8558L22.3597 13.5947L22.6532 14.1088L22.1392 14.3698Z" fill="#CC2E43"/>
    <path d="M21.2251 12.7893L20.9316 12.2752L21.4456 12.0142L21.7392 12.5282L21.2251 12.7893Z" fill="#CC2E43"/>
    <path d="M19.8989 12.5224L19.6054 12.0084L20.1194 11.7473L20.413 12.2614L19.8989 12.5224Z" fill="#CC2E43"/>
    <path d="M19.9785 14.1169C20.6866 14.3388 21.2483 14.8132 21.5887 15.4094C21.9292 16.0056 22.0401 16.709 21.8468 17.3887C21.5458 18.4466 22.1977 19.5881 23.2999 19.9334C24.4021 20.2787 25.5437 19.699 25.8446 18.6411C26.3389 16.9035 26.0497 15.0995 25.1877 13.5818C24.3184 12.0677 22.8804 10.8471 21.07 10.2799C19.9678 9.93464 18.8263 10.5144 18.5253 11.5722C18.2244 12.6301 18.8762 13.7716 19.9785 14.1169ZM20.9141 10.8281C22.5703 11.3469 23.8827 12.4625 24.6714 13.8439C25.4604 15.2253 25.7257 16.8725 25.2735 18.4622C25.0583 19.2186 24.244 19.6321 23.4558 19.3852C22.6677 19.1383 22.2027 18.324 22.4179 17.5676C22.6549 16.7344 22.5144 15.8725 22.105 15.1473C21.6882 14.4257 21.0025 13.8407 20.1344 13.5688C19.3463 13.3219 18.8813 12.5076 19.0964 11.7512C19.3116 10.9947 20.126 10.5812 20.9141 10.8281Z" fill="#FE909E"/>
    <path d="M18.598 11.561C18.8976 10.5093 20.0337 9.93298 21.1308 10.2762C22.9328 10.8401 24.364 12.0535 25.2292 13.5587L24.7154 13.8193C23.9303 12.446 22.6241 11.337 20.9756 10.8212C20.1912 10.5757 19.3806 10.9869 19.1665 11.7388C18.9523 12.4908 19.4151 13.3004 20.1996 13.5458C21.0636 13.8162 21.7461 14.3977 22.1609 15.1151L21.6471 15.3757C21.3083 14.783 20.7492 14.3113 20.0444 14.0907C18.9473 13.7475 18.2985 12.6127 18.598 11.561Z" fill="#FE909E"/>
  </svg>
</template>

<script>
export default {
  name: 'PaBrIcon',
}
</script>
