<template>
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0424418" y="0.171989" width="12.7705" height="3.66478" rx="1.83239" transform="matrix(0.854835 0.5189 -0.515301 0.857009 7.92096 6.76185)" fill="#E63950" stroke="#FE909E" stroke-width="0.25"/>
    <path d="M16.5016 14.8242L16.6793 15.5477L15.9592 15.7263L15.7815 15.0028L16.5016 14.8242Z" fill="#FF6D81"/>
    <path d="M16.9997 12.9038L17.1774 13.6273L16.4573 13.8059L16.2796 13.0824L16.9997 12.9038Z" fill="#FF6D81"/>
    <path d="M14.4404 12.8103L14.5505 13.2588L14.1042 13.3694L13.994 12.9209L14.4404 12.8103Z" fill="#FF6D81"/>
    <path d="M15.1183 12.3138L15.8414 12.1345L15.9294 12.4926L15.2063 12.6719L15.1183 12.3138Z" fill="#FF6D81"/>
    <path d="M12.9351 13.0672L13.2879 13.7263L12.9645 13.901L12.6117 13.2419L12.9351 13.0672Z" fill="#FF6D81"/>
    <path d="M12.5765 10.4057L11.8564 10.5843L12.0341 11.3078L12.7542 11.1292L12.5765 10.4057Z" fill="#FF6D81"/>
    <path d="M8.52573 9.98289L7.80566 10.1614L7.98335 10.8849L8.70342 10.7064L8.52573 9.98289Z" fill="#FF6D81"/>
    <path d="M8.06158 8.68339L7.61522 8.79406L7.72537 9.24255L8.17174 9.13188L8.06158 8.68339Z" fill="#FF6D81"/>
    <path d="M11.2589 10.8787L10.8125 10.9894L10.9226 11.4379L11.369 11.3272L11.2589 10.8787Z" fill="#FF6D81"/>
    <path d="M11.38 10.0442L11.2016 9.31767L10.8451 9.40606L11.0236 10.1326L11.38 10.0442Z" fill="#FF6D81"/>
    <rect x="0.0090966" y="0.17696" width="12.7817" height="3.66142" rx="1.83071" transform="matrix(0.74089 0.671626 -0.668117 0.744056 14.353 5.36389)" fill="#E63950" stroke="#FE909E" stroke-width="0.25"/>
    <path d="M21.2331 14.8952L21.2712 15.6394L20.5305 15.6777L20.4924 14.9335L21.2331 14.8952Z" fill="#FF6D81"/>
    <path d="M22.0827 13.1042L22.1208 13.8484L21.3801 13.8867L21.342 13.1425L22.0827 13.1042Z" fill="#FF6D81"/>
    <path d="M19.589 12.5257L19.6126 12.987L19.1535 13.0107L19.1299 12.5494L19.589 12.5257Z" fill="#FF6D81"/>
    <path d="M20.3484 12.1673L21.0922 12.1289L21.1111 12.4973L20.3672 12.5357L20.3484 12.1673Z" fill="#FF6D81"/>
    <path d="M18.0608 12.4913L18.283 13.2056L17.9325 13.3156L17.7104 12.6013L18.0608 12.4913Z" fill="#FF6D81"/>
    <path d="M18.2122 9.80937L17.4715 9.84763L17.5096 10.5918L18.2503 10.5536L18.2122 9.80937Z" fill="#FF6D81"/>
    <path d="M14.3157 8.62358L13.575 8.66184L13.6131 9.40606L14.3538 9.3678L14.3157 8.62358Z" fill="#FF6D81"/>
    <path d="M14.1043 7.25922L13.6452 7.28293L13.6688 7.74427L14.1279 7.72055L14.1043 7.25922Z" fill="#FF6D81"/>
    <path d="M16.8289 10.0231L16.3698 10.0468L16.3934 10.5082L16.8525 10.4845L16.8289 10.0231Z" fill="#FF6D81"/>
    <path d="M17.1062 9.22713L17.0679 8.47975L16.7013 8.49869L16.7395 9.24606L17.1062 9.22713Z" fill="#FF6D81"/>
    <rect x="0.119053" y="0.13073" width="12.754" height="3.6697" rx="1.83485" transform="matrix(0.998899 0.0469199 -0.0464772 0.998919 0.189801 3.47624)" fill="#E63950" stroke="#FE909E" stroke-width="0.25"/>
    <path d="M11.6372 6.41767L12.1365 6.96864L11.5882 7.4704L11.0888 6.91943L11.6372 6.41767Z" fill="#FF6D81"/>
    <path d="M11.1625 4.49165L11.6619 5.04262L11.1136 5.54438L10.6142 4.99341L11.1625 4.49165Z" fill="#FF6D81"/>
    <path d="M8.87162 5.63366L9.18118 5.9752L8.84125 6.28624L8.53169 5.9447L8.87162 5.63366Z" fill="#FF6D81"/>
    <path d="M9.23127 4.87234L9.78195 4.36846L10.0292 4.64121L9.47847 5.14509L9.23127 4.87234Z" fill="#FF6D81"/>
    <path d="M7.66794 6.581L8.29069 6.9915L8.0893 7.29995L7.46654 6.88944L7.66794 6.581Z" fill="#FF6D81"/>
    <path d="M6.08941 4.41289L5.54105 4.91465L6.04043 5.46562L6.58879 4.96386L6.08941 4.41289Z" fill="#FF6D81"/>
    <path d="M2.32769 5.98028L1.77933 6.48203L2.27871 7.033L2.82707 6.53125L2.32769 5.98028Z" fill="#FF6D81"/>
    <path d="M1.30221 5.05994L0.962287 5.37098L1.27185 5.71252L1.61177 5.40148L1.30221 5.05994Z" fill="#FF6D81"/>
    <path d="M5.15573 5.45911L4.8158 5.77015L5.12536 6.11169L5.46529 5.80065L5.15573 5.45911Z" fill="#FF6D81"/>
    <path d="M4.86603 4.66727L4.36454 4.11396L4.09308 4.36234L4.59458 4.91565L4.86603 4.66727Z" fill="#FF6D81"/>
    <rect x="-0.0067986" y="0.177064" width="10.4594" height="2.96165" rx="1.48082" transform="matrix(0.677716 0.735323 -0.732105 0.681192 3.52392 6.53899)" fill="#E63950" stroke="#FE909E" stroke-width="0.25"/>
    <path d="M7.41185 11.6223L7.38847 12.234L6.77968 12.2105L6.80306 11.5988L7.41185 11.6223Z" fill="#FF6D81"/>
    <path d="M8.42357 14.8259L8.40019 15.4376L7.7914 15.4141L7.81477 14.8024L8.42357 14.8259Z" fill="#FF6D81"/>
    <path d="M9.24974 13.4233L9.22636 14.035L8.61757 14.0115L8.64095 13.3998L9.24974 13.4233Z" fill="#FF6D81"/>
    <path d="M7.83763 13.664L7.81426 14.2757L7.20546 14.2522L7.22884 13.6405L7.83763 13.664Z" fill="#FF6D81"/>
    <path d="M7.25176 12.7657L7.23727 13.1449L6.85988 13.1303L6.87437 12.7512L7.25176 12.7657Z" fill="#FF6D81"/>
    <path d="M7.89937 12.5285L8.51075 12.5521L8.49917 12.8549L7.8878 12.8313L7.89937 12.5285Z" fill="#FF6D81"/>
    <path d="M6.00545 12.6245L6.13485 13.2253L5.84008 13.2894L5.71068 12.6886L6.00545 12.6245Z" fill="#FF6D81"/>
    <path d="M6.32253 10.4414L5.71373 10.418L5.69036 11.0296L6.29915 11.0531L6.32253 10.4414Z" fill="#FF6D81"/>
    <path d="M3.22096 9.18241L2.61217 9.15892L2.58879 9.77062L3.19759 9.7941L3.22096 9.18241Z" fill="#FF6D81"/>
    <path d="M4.67604 8.4622L4.06725 8.43871L4.04387 9.0504L4.65267 9.07389L4.67604 8.4622Z" fill="#FF6D81"/>
    <path d="M3.14863 8.05069L2.77124 8.03613L2.75675 8.41531L3.13414 8.42987L3.14863 8.05069Z" fill="#FF6D81"/>
    <path d="M4.32838 9.85819L3.71959 9.8347L3.69621 10.4464L4.30501 10.4699L4.32838 9.85819Z" fill="#FF6D81"/>
    <path d="M5.17597 10.5136L4.79859 10.499L4.7841 10.8782L5.16148 10.8928L5.17597 10.5136Z" fill="#FF6D81"/>
    <path d="M5.45992 9.88275L5.48339 9.26847L5.18202 9.25684L5.15855 9.87112L5.45992 9.88275Z" fill="#FF6D81"/>
    <path d="M5.21938 11.774L4.63308 11.5983L4.54688 11.8887L5.13318 12.0644L5.21938 11.774Z" fill="#FF6D81"/>
    <rect x="0.0490381" y="0.170209" width="10.4439" height="2.96629" rx="1.48314" transform="matrix(0.874254 0.485469 -0.481949 0.876199 11.0941 -0.00273457)" fill="#E63950" stroke="#FE909E" stroke-width="0.25"/>
    <path d="M16.422 3.62099L16.5906 4.20921L16.0052 4.37868L15.8365 3.79047L16.422 3.62099Z" fill="#FF6D81"/>
    <path d="M18.381 6.34438L18.5496 6.9326L17.9642 7.10207L17.7955 6.51385L18.381 6.34438Z" fill="#FF6D81"/>
    <path d="M18.7286 4.75234L18.8973 5.34056L18.3118 5.51003L18.1432 4.92181L18.7286 4.75234Z" fill="#FF6D81"/>
    <path d="M17.4649 5.42568L17.6336 6.0139L17.0482 6.18337L16.8795 5.59515L17.4649 5.42568Z" fill="#FF6D81"/>
    <path d="M16.6263 4.75699L16.7309 5.12162L16.368 5.22667L16.2634 4.86204L16.6263 4.75699Z" fill="#FF6D81"/>
    <path d="M17.1677 4.32771L17.7556 4.15753L17.8391 4.44871L17.2512 4.6189L17.1677 4.32771Z" fill="#FF6D81"/>
    <path d="M15.3995 5.01579L15.7099 5.54556L15.45 5.69928L15.1396 5.1695L15.3995 5.01579Z" fill="#FF6D81"/>
    <path d="M15.0194 2.84214L14.434 3.01161L14.6027 3.59983L15.1881 3.43036L15.0194 2.84214Z" fill="#FF6D81"/>
    <path d="M11.6796 2.62388L11.0942 2.79335L11.2628 3.38157L11.8483 3.2121L11.6796 2.62388Z" fill="#FF6D81"/>
    <path d="M12.8378 1.48154L12.2524 1.65102L12.421 2.23923L13.0065 2.06976L12.8378 1.48154Z" fill="#FF6D81"/>
    <path d="M11.2585 1.57212L10.8956 1.67717L11.0001 2.0418L11.363 1.93675L11.2585 1.57212Z" fill="#FF6D81"/>
    <path d="M12.9433 2.9166L12.3578 3.08607L12.5265 3.67429L13.1119 3.50482L12.9433 2.9166Z" fill="#FF6D81"/>
    <path d="M13.9518 3.27207L13.5889 3.37712L13.6935 3.74175L14.0564 3.6367L13.9518 3.27207Z" fill="#FF6D81"/>
    <path d="M14.0271 2.58357L13.8577 1.99286L13.5679 2.07676L13.7373 2.66747L14.0271 2.58357Z" fill="#FF6D81"/>
    <path d="M14.3869 4.45506L13.7754 4.47289L13.7841 4.7758L14.3957 4.75796L14.3869 4.45506Z" fill="#FF6D81"/>
  </svg>
</template>

<script>
export default {
  name: 'KpBrIcon',
}
</script>
