





























































import { Component, Prop, Vue } from 'vue-property-decorator'

import BacteriaFilterService from '@/modules/shared/services/BacteriaFilterService'

import Icon from '@/modules/shared/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class SubSpecieAutocomplete extends Vue {
  $refs!: {
    autocomplete: any;
  }

  @Prop({ default: () => [], type: Array })
  private readonly value!: unknown[]

  @Prop({ default: 2 })
  private readonly maxVisible!: number

  @Prop({ default: false, type: Boolean })
  private readonly returnObject!: boolean

  @Prop({ default: '' })
  private readonly placeholder!: string

  @Prop({ required: true })
  private readonly groupId!: number

  private subSpecies = this.value

  private loading = false

  private async getsubSpecies() {
    this.loading = true

    try {
      const { data } = await BacteriaFilterService.getSubSpecies(this.groupId)

      this.subSpecies = data
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  private removeSubSpecies(index: number) {
    const deleteCount = (index === this.maxVisible) ? Infinity : 1
    const copyValue = [...this.value]

    copyValue.splice(index, deleteCount)

    this.$emit('input', copyValue)
  }

  handleTouchOutside(event: Event) {
    const target = event.target as Element
    const { autocomplete } = this.$refs

    try {
      if (autocomplete.isFocused && !target.closest('.v-autocomplete__content')) {
        autocomplete.isFocused = false
      }
    } catch (_) { /** */ }
  }

  mounted() {
    this.getsubSpecies()
    document.body.addEventListener('touchmove', this.handleTouchOutside)
  }

  destroyed() {
    document.body.removeEventListener('touchmove', this.handleTouchOutside)
  }
}
