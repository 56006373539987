<template>
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0918 3.29443L19.8094 3.55742L19.9848 4.41233L21.2671 4.14934L21.0918 3.29443Z" fill="#E63950"/>
    <path d="M21.6013 7.17566L21.7056 6.30958L19.9894 6.10334L19.8852 6.96953L21.6013 7.17566Z" fill="#E63950"/>
    <path d="M19.5836 8.90762L19.2553 9.71619L20.903 10.3853L21.2314 9.57658L19.5836 8.90762Z" fill="#E63950"/>
    <path d="M18.014 11.9584L17.3942 12.6868L18.7191 13.3108L19.339 12.5824L18.014 11.9584Z" fill="#E63950"/>
    <path d="M15.9421 15.726L16.821 15.1725L15.9338 13.9518L15.0548 14.5053L15.9421 15.726Z" fill="#E63950"/>
    <path d="M9.28906 12.8628L7.90303 13.2435L7.97469 13.9807L9.36072 13.6L9.28906 12.8628Z" fill="#E63950"/>
    <path d="M8.10701 10.6495L7.64665 11.4218L9.30285 11.8116L9.76321 11.0393L8.10701 10.6495Z" fill="#E63950"/>
    <path d="M18.7745 0.336605L17.7972 1.81799L18.5256 2.29848L19.5029 0.817095L18.7745 0.336605Z" fill="#E63950"/>
    <path d="M14.3166 1.3188L13.7025 1.93889L14.9461 3.17038L15.5601 2.5503L14.3166 1.3188Z" fill="#E63950"/>
    <path d="M14.6316 5.98613L14.6687 5.11458L12.8927 5.03858L12.8557 5.91013L14.6316 5.98613Z" fill="#E63950"/>
    <path d="M13.2342 9.33631L13.8129 8.68346L12.4467 7.47295L11.8681 8.12566L13.2342 9.33631Z" fill="#E63950"/>
    <path d="M10.4183 10.5219L11.2221 10.1822L10.5095 8.49526L9.70567 8.83502L10.4183 10.5219Z" fill="#E63950"/>
    <path d="M11.4131 13.2014C11.6723 13.1539 11.9135 13.3216 11.9613 13.5825L12.1321 14.5144C12.2738 15.2879 11.768 16.0259 10.9997 16.1667C10.7404 16.2142 10.5435 16.4622 10.5913 16.7231C10.6579 17.0866 10.5788 17.4382 10.4011 17.7212C10.204 18.0559 9.86625 18.3008 9.4589 18.3754L8.53317 18.5451C8.27394 18.5926 8.03275 18.4249 7.98493 18.1639C7.93711 17.903 8.10317 17.6607 8.36241 17.6132L9.28813 17.4435C9.5473 17.396 9.71343 17.1537 9.66561 16.8928C9.52387 16.1193 10.0605 15.3756 10.8289 15.2348C11.0881 15.1873 11.2542 14.945 11.2064 14.6841L11.0356 13.7522C10.9878 13.4912 11.1539 13.2489 11.4131 13.2014Z" fill="#BD4859"/>
    <path d="M12.8838 14.9177C13.143 14.8702 13.3842 15.0379 13.432 15.2988L13.6028 16.2307C13.7445 17.0042 13.2387 17.7422 12.4704 17.883C12.2111 17.9305 12.0142 18.1785 12.062 18.4394C12.1286 18.8029 12.0495 19.1545 11.8718 19.4375C11.6747 19.7722 11.3369 20.0171 10.9296 20.0917L10.0039 20.2614C9.74464 20.3089 9.50345 20.1412 9.45563 19.8802C9.40781 19.6193 9.57388 19.377 9.83311 19.3295L10.7588 19.1598C11.018 19.1124 11.1841 18.87 11.1363 18.6091C10.9946 17.8356 11.5312 17.0919 12.2996 16.9511C12.5588 16.9036 12.7249 16.6613 12.6771 16.4004L12.5063 15.4685C12.4585 15.2075 12.6246 14.9652 12.8838 14.9177Z" fill="#BD4859"/>
    <path d="M11.7056 14.6271C11.7445 14.3101 11.5243 14.0302 11.2052 13.991L10.0653 13.8512C9.11924 13.7352 8.26255 14.4002 8.14729 15.3399C8.1084 15.657 7.8225 15.9131 7.50331 15.8739C7.05878 15.8194 6.639 15.9403 6.30932 16.1757C5.91848 16.4381 5.64637 16.8644 5.58526 17.3626L5.44639 18.4949C5.4075 18.812 5.62768 19.0918 5.94686 19.1309C6.26605 19.1701 6.54732 18.9518 6.58621 18.6347L6.72509 17.5024C6.76397 17.1854 7.04525 16.967 7.36444 17.0062C8.31053 17.1222 9.17184 16.4196 9.28711 15.4797C9.32599 15.1627 9.60727 14.9443 9.92646 14.9835L11.0663 15.1233C11.3855 15.1624 11.6667 14.9441 11.7056 14.6271Z" fill="#BD4859"/>
    <path d="M9.27426 13.2904C9.41979 14.0001 9.84193 14.5816 10.3982 14.9486C10.9545 15.3156 11.6553 15.4747 12.365 15.3292C14.9639 14.7962 17.0992 13.2626 18.4517 11.2124C19.804 9.16217 20.3734 6.59552 19.8404 3.99662C19.6949 3.28697 19.2728 2.7054 18.7165 2.33844C18.1602 1.97148 17.4594 1.81232 16.7497 1.95785C15.3305 2.24889 14.4199 3.62938 14.711 5.04855C14.9529 6.22829 14.6933 7.39511 14.0834 8.33089C13.4632 9.25986 12.4928 9.95779 11.313 10.1997C10.6034 10.3453 10.0218 10.7674 9.65484 11.3237C9.28788 11.88 9.12872 12.5808 9.27426 13.2904Z" fill="#EC5569"/>
    <path d="M9.65551 11.3236C9.28855 11.8799 9.12939 12.5807 9.27492 13.2903C9.42046 14 9.8426 14.5815 10.3989 14.9485C10.9552 15.3155 11.656 15.4746 12.3656 15.3291C14.9645 14.7961 17.0999 13.2625 18.4524 11.2123L14.0841 8.33079C13.4639 9.25976 12.4934 9.95768 11.3137 10.1996C10.604 10.3452 10.0225 10.7673 9.65551 11.3236Z" fill="#E63950"/>
    <path d="M17.6768 6.48285L17.1963 7.21125L17.9247 7.69174L18.4052 6.96334L17.6768 6.48285Z" fill="#CC2E43"/>
    <path d="M16.6475 4.90472L16.167 5.63313L16.8954 6.11362L17.3759 5.38521L16.6475 4.90472Z" fill="#CC2E43"/>
    <path d="M17.751 3.31121L17.2705 4.03962L17.9989 4.52011L18.4794 3.7917L17.751 3.31121Z" fill="#CC2E43"/>
    <path d="M16.0101 10.127L16.7416 10.6095L17.2241 9.878L16.4926 9.39548L16.0101 10.127Z" fill="#CC2E43"/>
    <path d="M14.2803 11.6337L13.7998 12.3621L14.5282 12.8426L15.0087 12.1142L14.2803 11.6337Z" fill="#AE2538"/>
    <path d="M11.6221 11.4018L11.1416 12.1302L11.87 12.6107L12.3505 11.8823L11.6221 11.4018Z" fill="#AE2538"/>
    <path d="M12.3565 12.9955L11.876 13.7239L12.6044 14.2044L13.0849 13.476L12.3565 12.9955Z" fill="#AE2538"/>
    <path d="M14.3565 9.83636L13.876 10.5648L14.6044 11.0453L15.0849 10.3169L14.3565 9.83636Z" fill="#AE2538"/>
    <path d="M16.0557 7.26068L15.5752 7.98909L16.3036 8.46957L16.7841 7.74117L16.0557 7.26068Z" fill="#CC2E43"/>
    <path d="M11.2254 9.77225C12.2855 9.55485 13.1554 8.93103 13.7125 8.0862C14.2699 7.24147 14.5009 6.19626 14.2836 5.1362C13.9452 3.48626 15.0122 1.86874 16.6621 1.53038C18.312 1.19201 19.9296 2.259 20.2679 3.90894C20.8237 6.61894 20.2241 9.29874 18.8122 11.4502C17.3901 13.5949 15.1627 15.2009 12.4527 15.7566C10.8027 16.095 9.18522 15.028 8.84685 13.3781C8.50849 11.7281 9.57547 10.1106 11.2254 9.77225ZM12.2773 14.9017C14.7566 14.3933 16.7896 12.9252 18.0807 10.9676C19.3721 9.01028 19.9215 6.5635 19.413 4.08426C19.1711 2.90453 18.0172 2.14335 16.8374 2.38529C15.6577 2.62723 14.8965 3.78114 15.1385 4.96087C15.405 6.26038 15.1151 7.54025 14.444 8.56873C13.7627 9.59039 12.7002 10.3607 11.4007 10.6272C10.221 10.8691 9.45982 12.023 9.70176 13.2027C9.9437 14.3825 11.0976 15.1436 12.2773 14.9017Z" fill="#FE909E"/>
    <path d="M8.84781 13.378C9.18618 15.0279 10.8037 16.0949 12.4536 15.7565C15.1636 15.2008 17.3911 13.5948 18.8132 11.4501L18.0817 10.9675C16.7906 12.9251 14.7575 14.3932 12.2783 14.9016C11.0986 15.1436 9.94466 14.3824 9.70272 13.2027C9.46079 12.0229 10.222 10.869 11.4017 10.6271C12.7012 10.3606 13.7637 9.5903 14.445 8.56864L13.7135 8.08611C13.1563 8.93093 12.2864 9.55476 11.2264 9.77216C9.57644 10.1105 8.50945 11.728 8.84781 13.378Z" fill="#FE909E"/>
    <path d="M16.98 10.2491L16.2485 9.76657L16.0106 10.1272L16.7421 10.6097L16.98 10.2491Z" fill="#AE2538"/>
    <path d="M0.484375 8.68018L1.18824 8.86878L1.31397 8.39953L0.610108 8.21093L0.484375 8.68018Z" fill="#E63950"/>
    <path d="M1.19803 6.62116L0.930342 7.0263L1.73322 7.55655L2.00093 7.15135L1.19803 6.62116Z" fill="#E63950"/>
    <path d="M2.63352 6.26177L2.99842 5.94109L2.34491 5.1975L1.97999 5.51824L2.63352 6.26177Z" fill="#E63950"/>
    <path d="M4.17622 5.13447L4.66625 4.92632L4.16219 4.28559L3.67216 4.49375L4.17622 5.13447Z" fill="#E63950"/>
    <path d="M6.14559 3.77525L5.57016 3.83182L5.70768 4.66049L6.28308 4.60397L6.14559 3.77525Z" fill="#E63950"/>
    <path d="M8.74219 6.85767L9.52699 7.01349L9.67499 6.62868L8.89019 6.47286L8.74219 6.85767Z" fill="#E63950"/>
    <path d="M8.78043 8.25434L9.20201 7.9846L8.47469 7.37794L8.05311 7.64769L8.78043 8.25434Z" fill="#E63950"/>
    <path d="M0.900127 10.73L1.7557 10.2361L1.51282 9.81543L0.657256 10.3093L0.900127 10.73Z" fill="#E63950"/>
    <path d="M3.36572 11.3518L3.82589 11.1961L3.51372 10.2733L3.05355 10.4289L3.36572 11.3518Z" fill="#E63950"/>
    <path d="M4.36997 8.94971L4.1344 9.37433L4.99951 9.85465L5.23508 9.43004L4.36997 8.94971Z" fill="#E63950"/>
    <path d="M5.90135 7.6307L5.45062 7.8115L5.82911 8.75447L6.27975 8.57372L5.90135 7.6307Z" fill="#E63950"/>
    <path d="M7.59893 7.74192L7.11419 7.71072L7.0486 8.72802L7.53337 8.75916L7.59893 7.74192Z" fill="#E63950"/>
    <path d="M7.7706 6.16034C7.62976 6.11941 7.55144 5.97584 7.59266 5.83403L7.73984 5.3276C7.86201 4.90725 8.29769 4.66592 8.71522 4.78727C8.8561 4.82821 9.0159 4.75385 9.05712 4.61203C9.11452 4.41453 9.24151 4.25921 9.4005 4.16263C9.58197 4.04512 9.81113 4.00737 10.0325 4.07171L10.5356 4.21791C10.6765 4.25885 10.7547 4.40241 10.7135 4.54423C10.6723 4.68605 10.5293 4.76528 10.3884 4.72434L9.88532 4.57813C9.74447 4.5372 9.60141 4.61643 9.56019 4.75824C9.43803 5.17859 8.98561 5.41505 8.56804 5.2937C8.4272 5.25276 8.28413 5.33199 8.24291 5.47381L8.09573 5.98023C8.05452 6.12205 7.91148 6.20129 7.7706 6.16034Z" fill="#BD4859"/>
    <path d="M7.46592 4.9394C7.32507 4.89846 7.24676 4.7549 7.28797 4.61308L7.43515 4.10665C7.55732 3.6863 7.993 3.44498 8.41053 3.56632C8.55141 3.60727 8.71122 3.5329 8.75243 3.39109C8.80983 3.19358 8.93682 3.03827 9.09581 2.94168C9.27728 2.82417 9.50644 2.78642 9.72781 2.85076L10.2309 2.99696C10.3718 3.03791 10.45 3.18147 10.4088 3.32328C10.3676 3.4651 10.2246 3.54433 10.0837 3.50339L9.58063 3.35718C9.43979 3.31625 9.29672 3.39548 9.2555 3.53729C9.13334 3.95765 8.68092 4.19411 8.26335 4.07275C8.12251 4.03182 7.97944 4.11104 7.93823 4.25286L7.79104 4.75929C7.74983 4.9011 7.60679 4.98034 7.46592 4.9394Z" fill="#BD4859"/>
    <path d="M7.97933 5.37765C7.88099 5.52576 7.92085 5.71993 8.06998 5.81895L8.60253 6.17253C9.04457 6.46602 9.6367 6.34844 9.92822 5.90937C10.0266 5.76122 10.2327 5.705 10.3818 5.80401C10.5895 5.94191 10.8286 5.98629 11.0514 5.95128C11.3113 5.91803 11.553 5.77364 11.7075 5.54085L12.0588 5.01183C12.1571 4.86368 12.1172 4.66954 11.9681 4.57052C11.819 4.47151 11.6246 4.5101 11.5262 4.65824L11.175 5.18727C11.0766 5.33538 10.8822 5.374 10.7331 5.27499C10.291 4.9815 9.68721 5.11668 9.39567 5.55579C9.29734 5.7039 9.10291 5.74252 8.95378 5.64351L8.42122 5.28993C8.27209 5.19091 8.07769 5.2295 7.97933 5.37765Z" fill="#BD4859"/>
    <path d="M8.85869 6.64914C8.96306 6.25964 8.89782 5.86496 8.71234 5.54369C8.52685 5.22242 8.21767 4.96859 7.82816 4.86422C6.40168 4.482 4.95662 4.71338 3.77258 5.39693C2.58858 6.08056 1.66567 7.21633 1.28345 8.64281C1.17908 9.03232 1.24431 9.427 1.4298 9.74827C1.61528 10.0695 1.92447 10.3234 2.31397 10.4277C3.09293 10.6365 3.89018 10.1762 4.0989 9.39721C4.2724 8.74968 4.69235 8.23354 5.22909 7.91968C5.76927 7.61179 6.42624 7.50616 7.07376 7.67967C7.46327 7.78404 7.85795 7.71881 8.17922 7.53332C8.50049 7.34784 8.75432 7.03865 8.85869 6.64914Z" fill="#EC5569"/>
    <path d="M8.17804 7.53323C8.49931 7.34775 8.75314 7.03856 8.85751 6.64906C8.96188 6.25955 8.89665 5.86487 8.71116 5.5436C8.52568 5.22233 8.21649 4.9685 7.82698 4.86414C6.4005 4.48191 4.95545 4.71329 3.7714 5.39684L5.22791 7.91959C5.76809 7.6117 6.42506 7.50608 7.07259 7.67958C7.46209 7.78395 7.85677 7.71872 8.17804 7.53323Z" fill="#E63950"/>
    <path d="M2.97825 7.94412L3.39892 7.70125L3.15605 7.28059L2.73538 7.52346L2.97825 7.94412Z" fill="#CC2E43"/>
    <path d="M3.09739 8.98611L3.51806 8.74324L3.27519 8.32258L2.85452 8.56545L3.09739 8.98611Z" fill="#CC2E43"/>
    <path d="M2.15208 9.50467L2.57275 9.2618L2.32987 8.84113L1.90921 9.084L2.15208 9.50467Z" fill="#CC2E43"/>
    <path d="M4.71656 6.54532L4.47266 6.12288L4.05021 6.36678L4.29411 6.78922L4.71656 6.54532Z" fill="#CC2E43"/>
    <path d="M5.95286 6.22659L6.37353 5.98372L6.13066 5.56306L5.70999 5.80593L5.95286 6.22659Z" fill="#AE2538"/>
    <path d="M7.21849 7.00442L7.63915 6.76155L7.39628 6.34089L6.97562 6.58376L7.21849 7.00442Z" fill="#AE2538"/>
    <path d="M7.24974 6.02786L7.6704 5.78499L7.42753 5.36433L7.00687 5.6072L7.24974 6.02786Z" fill="#AE2538"/>
    <path d="M5.46849 7.10208L5.88915 6.85921L5.64628 6.43854L5.22562 6.68142L5.46849 7.10208Z" fill="#AE2538"/>
    <path d="M3.98021 7.96097L4.40087 7.7181L4.158 7.29743L3.73733 7.5403L3.98021 7.96097Z" fill="#CC2E43"/>
    <path d="M7.00956 7.91417C6.42771 7.75826 5.83927 7.85206 5.35142 8.13377C4.86353 8.4154 4.48808 8.87811 4.33217 9.45995C4.08952 10.3656 3.15538 10.9049 2.24977 10.6622C1.34415 10.4196 0.804829 9.48544 1.04749 8.57982C1.44605 7.09237 2.41222 5.90781 3.65105 5.18865C4.89329 4.47537 6.40223 4.23092 7.88969 4.62948C8.7953 4.87214 9.33462 5.80628 9.09196 6.71189C8.84931 7.6175 7.91517 8.15682 7.00956 7.91417ZM7.76395 5.09872C6.40315 4.7341 5.02536 4.95835 3.89495 5.61109C2.76446 6.26368 1.88135 7.34476 1.51673 8.70556C1.34322 9.35309 1.72797 10.0195 2.3755 10.193C3.02303 10.3665 3.68943 9.98175 3.86293 9.33422C4.05405 8.62095 4.51725 8.05609 5.10752 7.71132C5.70123 7.37252 6.42202 7.25381 7.13529 7.44493C7.78282 7.61843 8.44922 7.23368 8.62272 6.58616C8.79623 5.93863 8.41148 5.27223 7.76395 5.09872Z" fill="#FE909E"/>
    <path d="M9.09258 6.71197C9.33524 5.80636 8.79592 4.87222 7.89031 4.62957C6.40285 4.231 4.89391 4.47545 3.65167 5.18873L3.89557 5.61118C5.02598 4.95843 6.40377 4.73418 7.76457 5.09881C8.4121 5.27231 8.79685 5.93871 8.62334 6.58624C8.44984 7.23377 7.78344 7.61852 7.13591 7.44501C6.42264 7.25389 5.70185 7.3726 5.10814 7.71141L5.35204 8.13385C5.83989 7.85214 6.42833 7.75835 7.01018 7.91425C7.91579 8.15691 8.84993 7.61759 9.09258 6.71197Z" fill="#FE909E"/>
    <path d="M4.26505 6.243L4.50895 6.66545L4.7172 6.54522L4.4733 6.12277L4.26505 6.243Z" fill="#AE2538"/>
  </svg>
</template>

<script>
export default {
  name: 'SeBrIcon',
}
</script>
